<template>
  <div>
    <div class="white">
      <v-row>
        <v-col cols="2">
          <render-input
            :input="fieldConfig.datePicker"
            v-model="model.datePicker"
          ></render-input>
        </v-col>
        <v-col cols="2">
          <render-input
            :input="fieldConfig.monthPicker"
            v-model="model.monthPicker"
          ></render-input>
        </v-col>

      </v-row>

      <div v-for="(item,index) in items">

        <excel-clipboard-to-object
          @result="getResult"
          :height="100"
        ></excel-clipboard-to-object>

        <save-content-image class="mt-5">
          <table
            class="tg"
            style="undefined;table-layout: fixed; width: 545px;margin: 3px;"
          >
            <colgroup>
              <col style="width: 96.283582px">
              <col style="width: 88.283582px">
              <col style="width: 88.283582px">
              <col style="width: 117.283582px">
              <col style="width: 64.283582px">
              <col style="width: 90.283582px">
            </colgroup>
            <thead>
              <tr>
                <th
                  class="tg-fuxe"
                  colspan="4"
                ><span style="font-weight:bold">บิลค่าเช่าห้อง</span></th>
                <th
                  class="tg-0pky"
                  colspan="2"
                >รอบเดือน <span style="font-weight:bold">ม.ค. 2566</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  class="tg-cey4"
                  colspan="4"
                >{{ item }}</td>
                <td
                  class="tg-4dm3"
                  colspan="2"
                >วันที่พิมพ์ 28/01/2566</td>
              </tr>
              <tr>
                <td class="tg-72fj">รายการ</td>
                <td class="tg-uzvj">เลขครั้งก่อน</td>
                <td class="tg-uzvj">เลขครั้งนี้</td>
                <td class="tg-uzvj">จำนวนหน่วยที่ใช้</td>
                <td class="tg-uzvj">หน่วยละ</td>
                <td class="tg-uzvj">จำนวนเงิน</td>
              </tr>
              <tr>
                <td class="tg-1do4">ค่าน้ำ</td>
                <td class="tg-lboi">240</td>
                <td class="tg-lboi">241</td>
                <td class="tg-lboi">1</td>
                <td class="tg-lboi">18</td>
                <td class="tg-lboi">100</td>
              </tr>
              <tr>
                <td class="tg-1do4">ค่าไฟ</td>
                <td class="tg-lboi"> </td>
                <td class="tg-lboi">117</td>
                <td class="tg-lboi">117</td>
                <td class="tg-lboi">7</td>
                <td class="tg-lboi">819</td>
              </tr>
              <tr>
                <td class="tg-1do4">ค่าเช่า</td>
                <td
                  class="tg-9wq8"
                  colspan="4"
                > &nbsp;&nbsp;&nbsp;</td>
                <td class="tg-lboi">3,500</td>
              </tr>
              <tr>
                <td class="tg-tuxf">รวมทั้งสิ้น</td>
                <td
                  class="tg-blwt"
                  colspan="4"
                > &nbsp;&nbsp;&nbsp;</td>
                <td class="tg-mlpm">4,419</td>
              </tr>
              <tr>
                <td
                  class="tg-m4wt"
                  colspan="6"
                >*ค่าน้ำขั้นต่ำ 100 ครับ</td>
              </tr>
              <tr>
                <td
                  class="tg-j0pp"
                  colspan="6"
                >กรุณาชำระเงินภายในวันที่ 1-5 ของทุกๆเดือน&nbsp;&nbsp;&nbsp;ตามเลขบัญชีด้านล่างครับ</td>
              </tr>
              <tr>
                <td
                  class="tg-g7sd"
                  colspan="6"
                >ธนาคารไทยพาณิชย์ เลขบัญชี 927-267022-9 นายภรัณเมศร์&nbsp;&nbsp;&nbsp;กุลไพบูลย์กิจ</td>
              </tr>
            </tbody>
          </table>
          <v-divider class="mb-3"></v-divider>
        </save-content-image>
      </div>
    </div>
  </div>
</template>


<script>
import { InputType } from "../js/constants";

export default {
  data() {
    return {
      items: ["ห้อง 1 (ทิศเหนือ)", "ห้อง 2 (ห้องกลาง)", "ห้อง 3 (ทิศใต้)"],
      model: {
        datePicker: "",
        monthPicker: "",
      },
    };
  },
  methods: {
    getResult(res) {
      er("res", res);
    },
  },
  computed: {
    fieldConfig() {
      return {
        datePicker: {
          text: "วันที่พิมพ์",
          type: InputType.datePicker,
        },
        monthPicker: {
          text: "รอบเดือน",
          type: InputType.monthPicker,
        },
      };
    },
  },
};
</script>

<style type="text/css">
.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg .tg-lboi {
  border-color: inherit;
  text-align: left;
  vertical-align: middle;
}
.tg .tg-blwt {
  background-color: #d0cece;
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-cey4 {
  border-color: inherit;
  font-size: 16px;
  text-align: left;
  vertical-align: top;
}
.tg .tg-9wq8 {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-1do4 {
  border-color: inherit;
  font-size: 16px;
  text-align: left;
  vertical-align: middle;
}
.tg .tg-fuxe {
  border-color: inherit;
  font-size: 18px;
  text-align: left;
  vertical-align: top;
}
.tg .tg-0pky {
  border-color: inherit;
  text-align: left;
  vertical-align: top;
}
.tg .tg-4dm3 {
  border-color: inherit;
  font-size: 15px;
  text-align: left;
  vertical-align: middle;
}
.tg .tg-72fj {
  border-color: inherit;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-uzvj {
  border-color: inherit;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-tuxf {
  background-color: #d0cece;
  border-color: inherit;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  vertical-align: middle;
}
.tg .tg-mlpm {
  background-color: #d0cece;
  border-color: inherit;
  color: #f00;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
}
.tg .tg-m4wt {
  background-color: #fff;
  border-color: inherit;
  font-size: 16px;
  text-align: left;
  vertical-align: middle;
}
.tg .tg-j0pp {
  border-color: inherit;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
}
.tg .tg-g7sd {
  border-color: inherit;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
}
</style>